body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.disable-text-selection {
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

.MuiDataGrid-root .MuiDataGrid-cell {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.option {
  background-color: #f9f7ff;
  border-radius: 16px;
  padding: 16px;
  border-color: rgba(13, 5, 20, 0.16);
  border: 2px;
  cursor: pointer;
  transition: all 200ms ease;
}

.correct-choice {
  background-color: #87dd9f;
}

.incorrect-choice {
  background-color: #f18f8f;
}

#hero {
  background-image: url('../public/resexamsg-hero.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (max-width: 576px) {
  #hero {
  }
}

#hero-text {
  padding: 1rem;
  margin-top: 10rem;
  padding-bottom: 50px;
  max-width: 32rem;
  font-size: 2.25rem;
  color: #ffffff;
  /* border: solid white 2px; */
  /* padding: 2.5rem;
  margin-left: 0;
  margin-right: 0; */
}

.button-font {
  font-family: sans-serif;
}

.MuiButton-root.MuiButton-outlined,
.MuiButton-root.MuiButton-contained {
  text-transform: capitalize;
  font-family: sans-serif;
}

.MuiButton-root.MuiButton-contained {
  background-color: #395b50;
}

.MuiButton-root.MuiButton-outlined {
  border-color: #395b50;
  color: #395b50;
}

.MuiButton-root.MuiButton-text {
  text-transform: capitalize;
  font-family: sans-serif;
}

.MuiButton-root.MuiButton-contained:hover {
  opacity: 0.8;
  transform: scale(1.05);
  background-color: #395b50;
}

.MuiButton-root.MuiButton-outlined:hover {
  opacity: 0.8;
  transform: scale(1.05);
  border-color: #395b50;
  color: #395b50;
}

.MuiButton-root > .MuiTypography-root {
  font-family: sans-serif;
  font-weight: 700;
}

.MuiTypography-root {
  font-family: sans-serif !important;
}
